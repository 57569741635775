.simpleCard{
    color: rgb(255, 255, 255);
    background-color: rgba(255, 166, 0, 0.13);
    border-radius: 5px;
    width:250px;
}
.simpleCard h2,.simpleCard p{
    text-align: center;
    background-color: transparent;
}
