*{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #1b1622;
}
.App{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-section{
  display: block;
  width: 100%;
  background-color: transparent;
  margin-top: 0;
}
.white{
  color:white;
}
.grey{
  color:grey;
}