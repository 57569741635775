
.form-container{

    margin: 2rem 0;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap:1rem;
    background-color: transparent;
}
.form-container input{
    width:100%;
    background-color: transparent;
    width:min-content;
    padding: 0.5rem;
    color: white;
    border:1px solid grey;
    border-radius: 8px;
}
.form-container input:focus{
    
    outline-color: orange;
}
form{
  background-color: transparent;
  
}
.submit-btn{
    margin-left: 10px;
    border-radius: 8px;
    padding: 0.5rem;
    border: none;
    background-color: orange;
    cursor:pointer;
    color: white;
}
  .spinner{
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .form-container input{
      width: 100%;
    }
  }
  
  
  
  
  