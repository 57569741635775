
.display-container{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bar-container{
  width:70%;
}
.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    margin-top: 10px;
    width:100%;;
    gap: 5px;
    
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .bar-container{
    margin: 2rem;
    background-color:grey;
    width:80%;
    height:30px;
    border-radius:10px;
}
  .window{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  